// chart-lib.ts
// A minimal TypeScript library to render multiple line charts in a single SVG element.
// Active charts show lines (with corridor/gradient/anomalies) and interactive points.
// Inactive charts show only points.
// Axis titles/units are taken from the active series' own properties.

// ------------------ Interfaces ------------------

export interface ChartPoint {
  x: number
  y: number
}

export interface CorridorPoint {
  x: number
  y: number
  d: number // +/- range
}

export interface ChartSeries {
  name: string
  color: string
  data: ChartPoint[]
  corridor?: CorridorPoint[]
  isActive?: boolean

  // Each series can define its own axis labels/units
  xAxisTitle?: string | null
  xAxisUnit?: string | null
  yAxisTitle?: string | null
  yAxisUnit?: string | null

  /**
   * Optional tooltip formatter.
   * Should return HTML markup as a string.
   * For example: (point, series) => `<strong>${series.name}</strong><br/>X: ${point.x}<br/>Y: ${point.y}`
   */
  tooltipFormatter?: (point: ChartPoint, series: ChartSeries) => string
}

export interface ChartOptions {
  // Dimensions
  width?: number
  height?: number

  // Axes
  axisColor?: string

  // Margins
  margin?: { top: number; right: number; bottom: number; left: number }

  // Animation
  animate?: boolean // use minimal SMIL or no animation at all

  // Interactivity
  onPointClick?: (
    point: ChartPoint,
    series: ChartSeries,
    seriesIndex: number,
    pointIndex: number
  ) => void
  showTooltip?: boolean

  // Corridor / anomalies
  showAnomalies?: boolean // highlight anomalies in red if outside corridor

  // More options to be added...
  showInactive?: boolean
}

// ------------------ Main render function ------------------

/**
 * Renders the given seriesData in a single SVG inside container.
 * @param container The HTML element in which the chart should be rendered.
 * @param seriesData An array of ChartSeries items.
 * @param options ChartOptions to control appearance and behavior.
 */
export function renderCharts(
  container: HTMLElement,
  seriesDataSource: ChartSeries[],
  options: ChartOptions = {}
): void {
  // const seriesData = JSON.parse(JSON.stringify(seriesDataSource)).sort((a, b) => {
  //   // Active series first
  //   if (a.isActive && !b.isActive) return 1
  //   if (!a.isActive && b.isActive) return -1
  //   return 0
  // })

  const seriesData = seriesDataSource;

  // Clear container
  container.innerHTML = ''

  // Ensure container is positioned so that absolute children (tooltips) are positioned correctly
  if (getComputedStyle(container).position === 'static') {
    container.style.position = 'relative'
  }

  // Default options
  const {
    width,
    height,
    axisColor = '#000',
    margin = { top: 20, right: 20, bottom: 40, left: 50 },
    animate = false,
    onPointClick,
    showTooltip = false,
    showAnomalies = false,
    showInactive = false
  } = options

  // Calculate final width / height
  const containerRect = container.getBoundingClientRect()
  const chartWidth = width ?? containerRect.width
  const chartHeight = height ?? 500
  const chartYMarginTop = 1.4
  const chartXMin = 1

  // Create <svg>
  const svgNS = 'http://www.w3.org/2000/svg'
  const svg = document.createElementNS(svgNS, 'svg')
  svg.setAttribute('width', chartWidth.toString())
  svg.setAttribute('height', chartHeight.toString())
  svg.style.display = 'block'
  // svg.style.background = '#fff'
  container.appendChild(svg)

  // Fallback if container dimensions are zero
  if (chartWidth === 0) {
    svg.setAttribute('width', '500')
  }
  if (chartHeight === 0) {
    svg.setAttribute('height', '500')
  }

  // Inner chart area
  const innerWidth = (width ?? svg.clientWidth) - margin.left - margin.right
  const innerHeight = (height ?? svg.clientHeight) - margin.top - margin.bottom

  // Find global X min/max among all series
  let xMin = Number.POSITIVE_INFINITY
  let xMax = Number.NEGATIVE_INFINITY
  for (const s of seriesData) {
    for (const pt of s.data) {
      if (pt.x < xMin) xMin = pt.x
      if (pt.x > xMax) xMax = pt.x
    }
    // corridor can also contain x data
    if (s.corridor) {
      for (const cpt of s.corridor) {
        if (cpt.x < xMin) xMin = cpt.x
        if (cpt.x > xMax) xMax = cpt.x
      }
    }
  }
  if (xMin === Number.POSITIVE_INFINITY || xMax === Number.NEGATIVE_INFINITY) {
    xMin = 0
    xMax = 1
  }

  xMin = chartXMin ?? xMin

  // Determine active series (first active found or null)
  const activeSeries = seriesData.find((s) => s.isActive) ?? null

  // We'll define a separate scaleY for each series (for drawing lines/points).
  const yScaleMap = new Map<ChartSeries, (val: number) => number>()

  // Build x scale function
  const scaleX = (val: number) => {
    return margin.left + ((val - xMin) / (xMax - xMin)) * innerWidth
  }

  // For each series, compute minY / maxY from data and corridor
  for (const s of seriesData) {
    let yMin = Number.POSITIVE_INFINITY
    let yMax = Number.NEGATIVE_INFINITY

    for (const pt of s.data) {
      if (pt.y < yMin) yMin = pt.y
      if (pt.y > yMax) yMax = pt.y
    }
    if (s.corridor) {
      for (const cpt of s.corridor) {
        if (cpt.y - cpt.d < yMin) yMin = cpt.y - cpt.d
        if (cpt.y + cpt.d > yMax) yMax = cpt.y + cpt.d
      }
    }
    if (yMin === Number.POSITIVE_INFINITY || yMax === Number.NEGATIVE_INFINITY) {
      yMin = 0
      yMax = 1
    }
    if (yMax === yMin) {
      yMax = yMin + 1
    }

    yMax = yMax*chartYMarginTop

    const scaleY = (val: number) => {
      return margin.top + innerHeight - ((val - yMin) / (yMax - yMin)) * innerHeight
    }
    yScaleMap.set(s, scaleY)
  }

  // ------------------ Draw Axes ------------------
  // X axis line
  const xAxisLine = document.createElementNS(svgNS, 'line')
  xAxisLine.setAttribute('x1', margin.left.toString())
  xAxisLine.setAttribute('y1', (margin.top + innerHeight).toString())
  xAxisLine.setAttribute('x2', (margin.left + innerWidth).toString())
  xAxisLine.setAttribute('y2', (margin.top + innerHeight).toString())
  xAxisLine.setAttribute('stroke', axisColor)
  xAxisLine.setAttribute('stroke-width', '1')
  svg.appendChild(xAxisLine)

  // Basic X ticks
  const numXTicks = 20
  let rangeX = xMax - xMin
  if (rangeX < 1) {
    rangeX = 1
  }
  const rawStepX = rangeX / numXTicks
  const stepX = Math.ceil(rawStepX)
  xMin = Math.floor(xMin / stepX) * stepX
  xMax = Math.ceil(xMax / stepX) * stepX

  for (let val = xMin; val <= xMax; val += stepX) {
    const xx = scaleX(val)

    // Tick line
    const tickLine = document.createElementNS(svgNS, 'line')
    tickLine.setAttribute('x1', xx.toString())
    tickLine.setAttribute('y1', (margin.top + innerHeight).toString())
    tickLine.setAttribute('x2', xx.toString())
    tickLine.setAttribute('y2', (margin.top + innerHeight + 5).toString())
    tickLine.setAttribute('stroke', axisColor)
    svg.appendChild(tickLine)

    // Tick label
    const tickLabel = document.createElementNS(svgNS, 'text')
    tickLabel.setAttribute('x', xx.toString())
    tickLabel.setAttribute('y', (margin.top + innerHeight + 15).toString())
    tickLabel.setAttribute('fill', axisColor)
    tickLabel.setAttribute('text-anchor', 'middle')
    tickLabel.setAttribute('font-size', '10')
    tickLabel.textContent = val.toString()
    svg.appendChild(tickLabel)
  }

  // X axis title/unit
  if (activeSeries) {
    const xTitle = []
    if (activeSeries.xAxisTitle) xTitle.push(activeSeries.xAxisTitle)
    if (activeSeries.xAxisUnit) xTitle.push(`(${activeSeries.xAxisUnit})`)

    const xAxisLabel = document.createElementNS(svgNS, 'text')
    xAxisLabel.setAttribute('fill', axisColor)
    xAxisLabel.setAttribute('text-anchor', 'middle')
    xAxisLabel.setAttribute(
      'transform',
      `translate(${margin.left + innerWidth / 2}, ${margin.top + innerHeight + 35})`
    )
    xAxisLabel.textContent = xTitle.join(' ')
    svg.appendChild(xAxisLabel)
  }

  // Y axis for active series only
  if (activeSeries) {
    const scaleY = yScaleMap.get(activeSeries)!

    // Y axis line
    const yAxisLine = document.createElementNS(svgNS, 'line')
    yAxisLine.setAttribute('x1', margin.left.toString())
    yAxisLine.setAttribute('y1', margin.top.toString())
    yAxisLine.setAttribute('x2', margin.left.toString())
    yAxisLine.setAttribute('y2', (margin.top + innerHeight).toString())
    yAxisLine.setAttribute('stroke', axisColor)
    yAxisLine.setAttribute('stroke-width', '1')
    svg.appendChild(yAxisLine)

    // Basic Y ticks for the active series
    const dataYVals = activeSeries.data.map((d) => d.y)
    let minY = Math.min(...dataYVals)
    let maxY = Math.max(...dataYVals)
    if (minY === maxY) {
      minY = minY - 1
      maxY = maxY + 1
    }
    maxY = maxY*chartYMarginTop
    const numYTicks = 5
    let range = maxY - minY
    let rawStep = range / numYTicks
    let step = Math.ceil(rawStep)
    minY = Math.floor(minY / step) * step
    maxY = Math.ceil(maxY / step) * step

    for (let val = minY; val <= maxY; val += step) {
      const yy = scaleY(val)
      const yTickLine = document.createElementNS(svgNS, 'line')
      yTickLine.setAttribute('x1', margin.left.toString())
      yTickLine.setAttribute('y1', yy.toString())
      yTickLine.setAttribute('x2', (margin.left - 5).toString())
      yTickLine.setAttribute('y2', yy.toString())
      yTickLine.setAttribute('stroke', axisColor)
      svg.appendChild(yTickLine)

      const yTickLabel = document.createElementNS(svgNS, 'text')
      yTickLabel.setAttribute('x', (margin.left - 8).toString())
      yTickLabel.setAttribute('y', (yy + 3).toString())
      yTickLabel.setAttribute('fill', axisColor)
      yTickLabel.setAttribute('text-anchor', 'end')
      yTickLabel.setAttribute('font-size', '10')
      yTickLabel.textContent = val.toString()
      svg.appendChild(yTickLabel)
    }

    // Y axis title/unit
    if (activeSeries.yAxisTitle || activeSeries.yAxisUnit) {
      const yTitle = []
      if (activeSeries.yAxisTitle) yTitle.push(activeSeries.yAxisTitle)
      if (activeSeries.yAxisUnit) yTitle.push(`(${activeSeries.yAxisUnit})`)

      const yAxisLabel = document.createElementNS(svgNS, 'text')
      yAxisLabel.setAttribute('fill', axisColor)
      yAxisLabel.setAttribute('text-anchor', 'middle')
      yAxisLabel.setAttribute(
        'transform',
        `translate(${margin.left - 40}, ${margin.top + innerHeight / 2}) rotate(-90)`
      )
      yAxisLabel.textContent = yTitle.join(' ')
      svg.appendChild(yAxisLabel)
    }
  }

  // ------------------ Prepare <defs> for gradients, etc. ------------------
  const defs = document.createElementNS(svgNS, 'defs')
  svg.appendChild(defs)

  // Global variables to track selected point and tooltip
  let selectedCircle: SVGCircleElement | null = null
  let tooltipElem: HTMLDivElement | null = null

  const verticalLine = document.createElementNS(svgNS, 'line')
  verticalLine.setAttribute('stroke', '#aaa')
  verticalLine.setAttribute('stroke-width', '1')
  verticalLine.setAttribute('stroke-dasharray', '2,2')
  verticalLine.setAttribute('visibility', 'hidden')
  svg.appendChild(verticalLine)

  // ------------------ global click handler ------------------
  function removeTooltip() {
    if (tooltipElem && tooltipElem.parentNode) {
      tooltipElem.parentNode.removeChild(tooltipElem)
      tooltipElem = null
    }
    if (selectedCircle) {
      selectedCircle.setAttribute('stroke', 'none')
      selectedCircle = null
    }

    verticalLine.setAttribute('visibility', 'hidden')
  }


  const onSvgBackgroundClick = (ev: MouseEvent) => {
    removeTooltip()
  }
  svg.addEventListener('click', onSvgBackgroundClick)

  // ------------------ Tooltip helper ------------------
  /**
   * Creates and positions a tooltip next to a clicked point.
   * The tooltip content is determined by the series' tooltipFormatter (if provided) or a default format.
   */
  function showTooltipForPoint(
    pt: ChartPoint,
    series: ChartSeries,
    cx: number,
    cy: number
  ) {
    // 1) Create tooltip element
    const tooltip = document.createElement('div')
    tooltip.className = 'chart-tooltip'
    tooltip.style.position = 'absolute'
    tooltip.style.pointerEvents = 'none'
    tooltip.style.background = 'white'
    tooltip.style.color = 'black'
    tooltip.style.padding = '5px 8px'
    tooltip.style.borderRadius = '10px'
    tooltip.style.fontSize = '12px'
    tooltip.style.whiteSpace = 'nowrap'
    tooltip.style.zIndex = '1000'
    tooltip.style.boxShadow = '2px 4px 11px #00000026'

    if (series.tooltipFormatter) {
      tooltip.innerHTML = series.tooltipFormatter(pt, series)
    } else {
      tooltip.innerHTML = `<strong>${series.name}</strong><br/>X: ${pt.x}${
        series.xAxisUnit ? ' ' + series.xAxisUnit : ''
      }<br/>Y: ${pt.y}${series.yAxisUnit ? ' ' + series.yAxisUnit : ''}`
    }

    container.appendChild(tooltip)
    tooltipElem = tooltip

    // position tooltip
    const containerRect = container.getBoundingClientRect()
    const tooltipRect = tooltip.getBoundingClientRect()
    const svgRect = svg.getBoundingClientRect()

    const pointX = svgRect.left + cx
    const pointY = svgRect.top + cy
    const relativeX = pointX - containerRect.left
    const relativeY = pointY - containerRect.top

    let posX = relativeX + 10
    let posY = relativeY - tooltipRect.height / 2

    if (posX + tooltipRect.width > container.clientWidth) {
      posX = relativeX - tooltipRect.width - 10
    }
    if (posY < 0) {
      posY = 0
    } else if (posY + tooltipRect.height > container.clientHeight) {
      posY = container.clientHeight - tooltipRect.height
    }

    tooltip.style.left = posX + 'px'
    tooltip.style.top = posY + 'px'

    // 2) enable vertical line
    verticalLine.setAttribute('x1', cx.toString())
    verticalLine.setAttribute('x2', cx.toString())
    verticalLine.setAttribute('y1', margin.top.toString())
    verticalLine.setAttribute('y2', (margin.top + innerHeight).toString())
    verticalLine.setAttribute('visibility', 'visible')
  }

  // ------------------ Draw each series ------------------
  seriesData.forEach((s, sIndex) => {
    const scaleY = yScaleMap.get(s)!
    const isActive = !!s.isActive

    if(!isActive && !showInactive) {
      return
    }

    // For active series, draw corridor, gradient, line, anomalies, and interactive points.
    // if (isActive) {
      // 1) Corridor (if any)
      if (s.corridor && s.corridor.length > 1) {
        const corridorTop: string[] = []
        const corridorBottom: string[] = []

        s.corridor.forEach((cpt, i) => {
          const xPos = scaleX(cpt.x)
          const yPosTop = scaleY(cpt.y + cpt.d)
          const yPosBot = scaleY(cpt.y - cpt.d)
          if (i === 0) {
            corridorTop.push(`M ${xPos},${yPosTop}`)
          } else {
            corridorTop.push(`L ${xPos},${yPosTop}`)
          }
          // Build bottom path in reverse order
          corridorBottom.unshift(`L ${xPos},${yPosBot}`)
        })
        const corridorPath = corridorTop.join(' ') + ' ' + corridorBottom.join(' ')
        const corridorElem = document.createElementNS(svgNS, 'path')
        corridorElem.setAttribute('d', corridorPath)
        corridorElem.setAttribute('fill', s.color + '33') // semi-transparent fill
        corridorElem.setAttribute('stroke', 'none')
        svg.appendChild(corridorElem)
      }

      // 2) Gradient fill under the active line
      const gradId = `grad-${Math.random().toString(36).substr(2, 9)}`
      const linearGrad = document.createElementNS(svgNS, 'linearGradient')
      linearGrad.setAttribute('id', gradId)
      linearGrad.setAttribute('x1', '0')
      linearGrad.setAttribute('y1', '0')
      linearGrad.setAttribute('x2', '0')
      linearGrad.setAttribute('y2', '1')

      const stop1 = document.createElementNS(svgNS, 'stop')
      stop1.setAttribute('offset', '0%')
      stop1.setAttribute('stop-color', s.color)
      stop1.setAttribute('stop-opacity', '0.1')
      linearGrad.appendChild(stop1)

      const stop2 = document.createElementNS(svgNS, 'stop')
      stop2.setAttribute('offset', '100%')
      stop2.setAttribute('stop-color', s.color)
      stop2.setAttribute('stop-opacity', '0')
      linearGrad.appendChild(stop2)

      defs.appendChild(linearGrad)

      const fillPath = buildFillPath(s.data, scaleX, scaleY, margin, innerHeight)
      const fillElem = document.createElementNS(svgNS, 'path')
      fillElem.setAttribute('d', fillPath)
      fillElem.setAttribute('fill', `url(#${gradId})`)
      fillElem.setAttribute('stroke', 'none')
      svg.appendChild(fillElem)

      // 3) Main line
      const pathString = buildLinePath(s.data, scaleX, scaleY)
      const pathElem = document.createElementNS(svgNS, 'path')
      pathElem.setAttribute('d', pathString)
      pathElem.setAttribute('fill', 'none')
      pathElem.setAttribute('stroke', s.color)
      pathElem.setAttribute('stroke-width', '2')

      if (animate) {
        const lengthVal = pathLength(pathString)
        pathElem.setAttribute('stroke-dasharray', lengthVal.toString())
        pathElem.setAttribute('stroke-dashoffset', lengthVal.toString())

        const animateElem = document.createElementNS(svgNS, 'animate')
        animateElem.setAttribute('attributeName', 'stroke-dashoffset')
        animateElem.setAttribute('from', lengthVal.toString())
        animateElem.setAttribute('to', '0')
        animateElem.setAttribute('dur', '0.7s')
        animateElem.setAttribute('fill', 'freeze')
        pathElem.appendChild(animateElem)
      }

      svg.appendChild(pathElem)

      // 4) Anomalies (if enabled)
      if (showAnomalies && s.corridor) {
        const anomaliesPath = buildAnomaliesPath(s.data, s.corridor, scaleX, scaleY)
        if (anomaliesPath) {
          const anomaliesElem = document.createElementNS(svgNS, 'path')
          anomaliesElem.setAttribute('d', anomaliesPath)
          anomaliesElem.setAttribute('fill', 'none')
          anomaliesElem.setAttribute('stroke', 'red')
          anomaliesElem.setAttribute('stroke-width', '2')
          svg.appendChild(anomaliesElem)
        }
      }
    // } else if (showInactive) {
    //   // For inactive series, draw a simple grey line.
    //   const pathString = buildLinePath(s.data, scaleX, scaleY)
    //   const pathElem = document.createElementNS(svgNS, 'path')
    //   pathElem.setAttribute('d', pathString)
    //   pathElem.setAttribute('fill', 'none')
    //   pathElem.setAttribute('stroke', '#dbdbdb')
    //   pathElem.setAttribute('stroke-width', '2')
    //   svg.appendChild(pathElem)
    // }

    // 5) Draw points (always)

    let widthCx: number | null = null

    s.data.forEach((pt, i) => {
      if (pt.x == null || pt.y == null) return
      const cx = scaleX(pt.x)
      const cy = scaleY(pt.y)

      widthCx = widthCx ?? Math.max(cx, i < s.data.length - 1 ? scaleX(s.data[i + 1].x) : cx) - cx




      const hitRect = document.createElementNS(svgNS, 'rect')
      hitRect.setAttribute('x', (cx - widthCx/2).toString())
      hitRect.setAttribute('y', margin.top.toString())
      hitRect.setAttribute('width', widthCx)
      hitRect.setAttribute('height', innerHeight.toString())
      hitRect.setAttribute('fill', 'transparent')
      hitRect.style.cursor = 'pointer'
      svg.appendChild(hitRect)


      const circle = document.createElementNS(svgNS, 'circle')
      circle.setAttribute('cx', cx.toString())
      circle.setAttribute('cy', cy.toString())
      circle.setAttribute('r', '3')
      circle.setAttribute('fill', s.color)
      circle.setAttribute('stroke', 'none')
      circle.style.pointerEvents = 'none'
      svg.appendChild(circle)

      hitRect.addEventListener('mouseover', (ev) => {
        removeTooltip() 
        selectedCircle = circle
        circle.setAttribute('stroke', '#b3b3b3')
        circle.setAttribute('opacity', '0.81')
        circle.setAttribute('stroke-width', '8')
        showTooltipForPoint(pt, s, cx, cy)
      })

      hitRect.addEventListener('mouseout', (ev) => {
        removeTooltip()
      })


      hitRect.addEventListener('click', (ev) => {

        ev.stopPropagation()
        removeTooltip() 
        selectedCircle = circle
        circle.setAttribute('stroke', '#b3b3b3')
        circle.setAttribute('opacity', '0.81')
        circle.setAttribute('stroke-width', '8')
        showTooltipForPoint(pt, s, cx, cy)
        if (onPointClick) {
          onPointClick(pt, s, sIndex, i)
        }
      })
    })

  })

  // ------------------ Helper Functions ------------------

  // Build a path string "M x0,y0 L x1,y1 L x2,y2 ..."
  function buildLinePath(
    data: ChartPoint[],
    sx: (x: number) => number,
    sy: (y: number) => number
  ): string {
    if (!data.length) return ''
    let lastX = sx(firstX(data))
    let lastY = sy(firstY(data))
    const parts: string[] = []
    data.forEach((pt, i) => {
      let xPos, yPos
      if (pt.x === null || pt.y === null) {
        xPos = lastX
        yPos = lastY
      } else {
        lastX = xPos = sx(pt.x)
        lastY = yPos = sy(pt.y)
      }
      if (i === 0) {
        // parts.push(`M ${xPos},${yPos}`)
      } else if (i === 1) {
        parts.push(`M ${xPos},${yPos}`)
      } else {
        parts.push(`L ${xPos},${yPos}`)
      }
    })
    return parts.join(' ')
  }

  // first value x
  function firstX(data: ChartPoint[]): number {
    for (let i = 0; i < data.length; i++) {
      if (data[i].x !== null && data[i].y !== null) {
        return data[i].x
      }
    }
    return 0
  }

  // first value y
  function firstY(data: ChartPoint[]): number {
    for (let i = 0; i < data.length; i++) {
      if (data[i].y !== null && data[i].x !== null) {
        return data[i].y
      }
    }
    return 0
  }

  // Build fill path for gradient under the active line
  function buildFillPath(
    data: ChartPoint[],
    sx: (x: number) => number,
    sy: (y: number) => number,
    margin: { top: number; right: number; bottom: number; left: number },
    innerH: number
  ): string {
    if (!data.length) return ''
    
    // let d = ``
    let firstExistedX = 0
    let firstExistedY = 0
    let lastX = firstExistedX = sx(firstX(data))
    let lastY = firstExistedY = sy(firstY(data))
    let d = `M ${lastX},${lastY}`

    for (let i = 1; i < data.length; i++) {

      if (data[i].x === null || data[i].y === null) {
        d += ` L ${lastX},${lastY}`
      } else {
        lastX = sx(data[i].x)
        lastY = sy(data[i].y)
        d += ` L ${lastX},${lastY}`
      }
    }
    d += ` L ${lastX},${margin.top + innerH}`
    // d += ` L ${sx(data[0].x)},${margin.top + innerH} Z`
    d += ` L ${firstExistedX},${margin.top + innerH} Z`
    return d
  }

  // Approximate length of path (for stroke-dasharray animation)
  function pathLength(d: string): number {
    const coords = d.match(/[ML]\s*([\d.]+),([\d.]+)/g)
    if (!coords) return 0
    let total = 0
    let prevX = 0
    let prevY = 0
    let first = true
    coords.forEach((c) => {
      const parts = c.split(/[\s,]+/)
      const x = parseFloat(parts[1])
      const y = parseFloat(parts[2])
      if (!first) {
        const dx = x - prevX
        const dy = y - prevY
        total += Math.sqrt(dx * dx + dy * dy)
      }
      prevX = x
      prevY = y
      first = false
    })
    return total
  }

  // Build path for anomalies (red) where data is outside corridor
  function buildAnomaliesPath(
    data: ChartPoint[],
    corridor: CorridorPoint[],
    sx: (x: number) => number,
    sy: (y: number) => number
  ): string {
    if (!data.length || !corridor.length) return ''
    let d = ''
    let isInAnomaly = false
    for (let i = 0; i < data.length; i++) {
      const pt = data[i]
      if (pt.x == null || pt.y == null) {
        isInAnomaly = false
        continue
      }
      const cor = corridor[i]
      if (!cor) break
      const top = cor.y + cor.d
      const bot = cor.y - cor.d
      const outside = pt.y > top || pt.y < bot
      const xPos = sx(pt.x)
      const yPos = sy(pt.y)
      if (outside) {
        if (!isInAnomaly) {
          d += `M ${xPos},${yPos}`
          isInAnomaly = true
        } else {
          d += ` L ${xPos},${yPos}`
        }
      } else {
        if (isInAnomaly) {
          isInAnomaly = false
        }
      }
    }
    return d
  }
}