<template>

  <div v-if="parameters.length || countnutrients" class="setup-bx"
      @click="toggleSetup">
  <div class="setup">

    <!-- {{ props.setup }} -->


    <div 
      v-for="(s, ks) in parameters"
      class="setup-item">
        <i :class="s.icon" v-if="s.icon"></i>
        <template v-else-if="s.hint">{{ s.hint }}:</template>                  
        <b>
          {{ s.beforeValue }}
          {{ viewValue(s.unit, s.measure, s.value, s.dec) }}    
          {{ s.afterValue }} 
          <template v-if="s.unit && s.measure && $constants[s.unit] && $constants[s.unit][s.measure]">
            {{ $constants[s.unit][s.measure] ? $t($constants[s.unit][s.measure]) : '' }}
          </template>
        </b>
      </div>
    </div>
 

  </div>
 
  
</template>

<script setup lang="ts">

import { WeekPropsSmellEnumTr } from '@/types/diary';
import { viewValue, universal } from '@/types/other';

import { inject } from 'vue';
  
const { t } = useI18n();
const { $constants } = useNuxtApp();

// const emits = defineEmits(['click.comment'])

const props = defineProps({
  setup: {
    type: Object,
    required: true
  },
  nutrients: {
    type: Array,
    required: false
  }
})


const actionsToggleNutrientsEvent = inject('actionsToggleNutrientsEvent');
const toggleNutrients = () => {
  actionsToggleNutrientsEvent({  });
};


const actionsToggleSetupEvent = inject('actionsToggleSetupEvent');
const toggleSetup = () => {
  actionsToggleSetupEvent({  });
};

// const actionsToggleNutrientsEvent = inject('actionsToggleNutrientsEvent');

// const toggleNutrients = () => {

//   actionsToggleNutrientsEvent({  });

// };


const countnutrients = computed(() => {
  return props.nutrients?.length;
});

const parameters = computed(() => {
  var list = [];

  // var week = {
  //   wiki: false,
  //   hint: props.faza == 0 ? t('universal_type_faza_veg') : t('universal_type_faza_flo'),
  //   value: props.days,
  //   beforeValue: t('conditions_week'),
  //   afterValue: null,
  //   ico: 'week',
  //   unit: null,
  //   measure: null,
  //   onChange: null
  // };
  // list.push(week);


  if(!props.setup) return [];

  if(props.setup.height){
    var height = {
      hint: t('conditions_height'),
      value: props.setup.height,
      // vl: universal('length', useAuth().getLength(), props.setup.height)
      beforeValue: null,
      afterValue: null,
      ico: 'height',
      icon: 'icon-height',
      id: 'height',
      unit: 'length',
      measure: useAuth().getLength(),
      onChange: useAuth().setLength
    };
    list.push(height);
  }

  if(props.setup.light){
    var light = {
      hint: t('conditions_light_schedule'),
      value: props.setup.light,
      beforeValue: null,
      afterValue: t('set_hrs'),
      ico: 'light',
      icon: 'icon-light-schedule',
      id: 'light',
      unit: null,
      measure: null,
      onChange: null
    };
    list.push(light);
  }

  if(props.setup.air_temp){
    var air_temp = {
      hint: t('conditions_day_air_temperature'),
      value: props.setup.air_temp,
      beforeValue: null,
      afterValue: null,
      ico: 'air_temp',
      icon: 'icon-air-temperature',
      id: 'air-temp',
      unit: 'temperature',
      measure: useAuth().getTemperature(),
      onChange: useAuth().setTemperature
    };
    list.push(air_temp);
  }

  if(props.setup.ph){
    var ph = {
      hint: t('conditions_ph'),
      value: props.setup.ph,
      beforeValue: null,
      afterValue: null,
      ico: 'ph',
      icon: 'icon-ph',
      id: 'ph',
      unit: null,
      measure: null,
      onChange: null
    };
    list.push(ph);
  }

  if(props.setup.smell){
    var smell = {
      hint: t('conditions_smell'),
      value: null,
      beforeValue: t(WeekPropsSmellEnumTr[props.setup.smell]),
      afterValue: null,
      ico: 'smell',
      icon: 'icon-smell',
      id: 'smell',
      unit: null,
      measure: null,
      onChange: null
    };
    list.push(smell);
  }

  if(props.setup.ppm){
    var ppm = {
      hint: useAuth().getTds() == 'ppm' ? t('conditions_tds') : t('conditions_ec'),
      value: props.setup.ppm,
      beforeValue: null,
      afterValue: null,
      icon: 'icon-ec',
      id: 'ppm',
      unit: 'tds',
      measure: useAuth().getTds(),
      onChange: useAuth().setTds
    };
    list.push(ppm);
  }

  if(props.setup.air_hum){
    var air_hum = {
      hint: t('conditions_humidity'),
      value: props.setup.air_hum,
      beforeValue: null,
      afterValue: '%',
      ico: 'air_hum',
      icon: 'icon-air-humidity',
      id: 'air-hum',
      unit: null,
      measure: null,
      onChange: null
    };

    list.push(air_hum);
  }

  if(props.setup.solution_temp){
    var solution_temp = {
      hint: t('conditions_solution_temp'),
      value: props.setup.solution_temp,
      beforeValue: null,
      afterValue: null,
      ico: 'solution_temp',
      icon: 'icon-solution-temperature',
      id: 'solution-temp',
      unit: 'temperature',
      measure: useAuth().getTemperature(),
      onChange: useAuth().setTemperature
    };
    list.push(solution_temp);
  }

  if(props.setup.substrate_temp){
    var substrate_temp = {
      hint: t('conditions_substrate_temp'),
      value: props.setup.substrate_temp,
      beforeValue: null,
      afterValue: null,
      ico: 'substrate_temp',
      icon: 'icon-substrate-temperature',
      id: 'substrate-temp',
      unit: 'temperature',
      measure: useAuth().getTemperature(),
      onChange: useAuth().setTemperature
    };
    list.push(substrate_temp);
  }

  if(props.setup.night_air_temp){
    var night_air_temp = {
      hint: t('conditions_night_air_temp'),
      value: props.setup.night_air_temp,
      beforeValue: null,
      afterValue: null,
      ico: 'night_air_temp',
      icon: 'icon-night-air-temperature',
      id: 'night-air-temp',
      unit: 'temperature',
      measure: useAuth().getTemperature(),
      onChange: useAuth().setTemperature
    };
    list.push(night_air_temp);
  }

  if(props.setup.pot_size){
    var pot_size = {
      hint: t('conditions_pot_size'),
      value: props.setup.pot_size,
      beforeValue: null,
      dec: 0,
      afterValue: null,
      ico: 'pot_size',
      icon: 'icon-pot',
      id: 'pot-size',
      unit: 'volume',
      measure: useAuth().getVolume(),
      onChange: useAuth().setVolume
    };
    list.push(pot_size);
  }

  if(props.setup.watering_volume){
    var watering_volume = {
      hint: t('conditions_watering_volume'),
      value: props.setup.watering_volume,
      beforeValue: null,
      afterValue: null,
      ico: 'watering_volume',
      icon: 'icon-watering-volume',
      id: 'watering-volume',
      unit: 'volume',
      measure: useAuth().getVolume(),
      onChange: useAuth().setVolume
    };
    list.push(watering_volume);
  }

  if(props.setup.lamp_distance){
    var lamp_distance = {
      hint: t('conditions_lamp_to_plant'),
      value: props.setup.lamp_distance,
      beforeValue: null,
      afterValue: null,
      ico: 'lamp_distance',
      icon: 'icon-lamp-distance',
      id: 'lamp-distance',
      unit: 'length',
      measure: useAuth().getLength(),
      onChange: useAuth().setLength
    };
    list.push(lamp_distance);
  }

  if(props.setup.co2){
    var co2 = {
      hint: t('conditions_co2'),
      value: props.setup.co2,
      beforeValue: null,
      afterValue: 'PPM',
      ico: 'co2',
      icon: 'icon-co2',
      id: 'co2',
      unit: 'ppm',
      measure: null,
      onChange: null
    };
    list.push(co2);
  }






  if(props.setup.harvest_weight){
    list.push({
      tophint: t('harvest_view_parameters_whats_on_scales'),
      hint: t('harvest_view_parameters_bud_dry_weight'),
      value: props.setup.harvest_weight,
      beforeValue: null,
      afterValue: null,
      icon: 'icon-weight-dry',
      id: 'n_weight',
      unit: 'weight',
      measure: useAuth().getWeight(),
      onChange: useAuth().setWeight
    });
  }

  if(props.setup.harvest_wet_weight){
    list.push({
      tophint: t('harvest_view_parameters_whats_on_scales'),
      hint: t('harvest_view_parameters_bud_wet_weight'),
      value: props.setup.harvest_wet_weight,
      beforeValue: null,
      afterValue: null,
      icon: 'icon-weight-wet',
      id: 'n_wet_weight',
      unit: 'weight',
      measure: useAuth().getWeight(),
      onChange: useAuth().setWeight
    });
  }

  if(props.setup.harvest_plant){
    list.push({
      tophint: t('harvest_view_parameters_harvested'),
      hint: t('harvest_view_parameters_number_of_plants'),
      value: props.setup.harvest_plant,
      beforeValue: null,
      afterValue: null,
      icon: 'icon-few-plants',
      id: 'n_plant',
      unit: 'plant',
      measure: null,
      onChange: null
    });
  }

  if(props.setup.harvest_space){
    list.push({
      tophint: t('harvest_view_parameters_grow_room'),
      hint: t('harvest_view_parameters_grow_room_size'),
      value: props.setup.harvest_space,
      beforeValue: null,
      afterValue: null,
      icon: 'icon-space',
      id: 'n_space',
      unit: 'square',
      measure: useAuth().getLength(),
      onChange: useAuth().setLength
    });
  }









// const gw = computed (() => {
//   if(!props.weight) return false;
//   if(!props.watt) return false;
//   var w = universal('weight', useAuth().getWeight(), props.weight);
//   if(!w) return false;
//   return Math.round(w/props.watt*100)/100;
// });

if(props.setup.harvest_weight){
  let w = universal('weight', useAuth().getWeight(), props.setup.harvest_weight);
  let s = props.setup.harvest_watt;
  if(w){
    list.push({
      tophint: null,
      hint: null,
      value: Math.round(w/s*100)/100,
      beforeValue: null,
      afterValue: t($constants.weight[useAuth().getWeight()]) + '/' + t('universal_unit_watt') ,
      ico: null,
      id: 'gw',
      unit: null,
    });
  }
}

// const gm = computed (() => {
//   if(!props.weight) return false;
//   if(!props.space) return false;
//   var w = universal('weight', useAuth().getWeight(), props.weight);
//   var s = universal('square', useAuth().getLength(), props.space);
//   if(!w) return false;
//   if(!s) return false;
//   return Math.round(w/s*100)/100;
// });

if(props.setup.harvest_weight && props.setup.harvest_space){
  let w = universal('weight', useAuth().getWeight(), props.setup.harvest_weight);
  let s = universal('square', useAuth().getLength(), props.setup.harvest_space);
  if(w && s){
    list.push({
      tophint: null,
      hint: null,
      value: Math.round(w/s*100)/100,
      beforeValue: null,
      afterValue: t($constants.weight[useAuth().getWeight()]) + '/' + t($constants.square[useAuth().getLength()]),
      ico: null,
      id: 'gm',
      unit: null,
    });
  }
}

// const gp = computed (() => {
//   if(!props.weight) return false;
//   if(!props.plant) return false;
//   var w = universal('weight', useAuth().getWeight(), props.weight);
//   if(!w) return false;
//   return Math.round(w/props.plant*100)/100;
// });

if(props.setup.harvest_weight && props.setup.harvest_plant){
  let w = universal('weight', useAuth().getWeight(), props.setup.harvest_weight);
  let s = props.setup.harvest_plant;
  if(w){
    list.push({
      tophint: null,
      hint: null,
      value: Math.round(w/s*100)/100,
      beforeValue: null,
      afterValue: t($constants.weight[useAuth().getWeight()]) + '/' + t('universal_unit_plant'),
      ico: null,
      id: 'gp',
      unit: null,
    });
  }
}

// const ps = computed (() => {
//   if(!props.plant) return false;
//   if(!props.space) return false;
//   var s = universal('square', useAuth().getLength(), props.space);
//   if(!s) return false;
//   return Math.round(props.plant/s*100)/100;
// });

if(props.setup.harvest_plant && props.setup.harvest_space){
  let w = props.setup.harvest_plant;
  let s = universal('square', useAuth().getLength(), props.setup.harvest_space);
  if(s){
    list.push({
      tophint: null,
      hint: null,
      value: Math.round(w/s*100)/100,
      beforeValue: null,
      afterValue: t('universal_unit_plant') + '/' + t($constants.square[useAuth().getLength()]),
      ico: null,
      id: 'ps',
      unit: null,
    });
  }
}

// const ws = computed (() => {
//   if(!props.watt) return false;
//   if(!props.space) return false;
//   var s = universal('square', useAuth().getLength(), props.space);
//   if(!s) return false;
//   return Math.round(props.watt/s*100)/100;
// });

if(props.setup.harvest_watt && props.setup.harvest_space){
  let w = props.setup.harvest_watt;
  let s = universal('square', useAuth().getLength(), props.setup.harvest_space);
  if(s){
    list.push({
      tophint: null,
      hint: null,
      value: Math.round(w/s*100)/100,
      beforeValue: null,
      afterValue: t('universal_unit_watt') + '/' + t($constants.square[useAuth().getLength()]),
      ico: null,
      id: 'ws',
      unit: null,
    });
  }
}






  return list;


});

</script>




<style scoped>
 .setup-bx{
  position: relative;
 }
 .week_comment{
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 10px 0;
}
.date{
  font-size: 0.8rem;
  color: #999;
}

/* flex, overflow-x */
.setup{
  display: flex;
  gap: 0rem 1rem;
  /* overflow-x: auto; */
  flex-wrap: wrap;
  margin-top: 10px;
  position: relative;
}

.setup::-webkit-scrollbar {
  display: none;
}

.setup-item{ 
  flex-shrink: 0;
  cursor: pointer;
  /* padding: 6px 0; */
  display: flex;
  align-items: center;
  gap: 0.2rem;
  min-height: 30px;
}
.setup-item i{
  font-size: 1.2rem;
  vertical-align: middle;
}
/* .setup-item::after{
  content: '·';
  margin-left: 7px;
  margin-right: 5px;
  color: var(--un-text-color-gray);

} */
.setup-item:last-child::after{
  content: '';
}
.dots{
  padding: 6px 0;
    color: var(--un-text-color);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--un-background-color-gray);
    width: 52px;
    height: 35px;
    text-align: center;
    margin-top: -2px;
    font-size: 2rem;
    line-height: 0.9rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@container pc (max-width: 600px) {

  .setup{
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  
}



</style>
