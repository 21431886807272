<template>

  <div class="flw">
  
    <div class="dot-ttl">Enjoying this diary? Follow for more updates!</div>

    <div class="cnt">
      <div class="bl">
        
        <NuxtLink class="av" :to="diary.item_user.link">
          <img loading="lazy" :src="diary.item_user.avatar_little" :alt="diary.item_user.name" />
        </NuxtLink>
        
        <NuxtLink class="nm" :to="diary.item_user.link">
          {{ diary.item_user.name }}
        </NuxtLink>


        <UiButton
          v-if="!diary?.item_user?.is_follow"
          @click="handleFollowUser"
          name="Follow Author"
          type="secondary"
          :is-loading="isLoadingFollowUser"
        />

        <UiButton
          v-if="diary?.item_user?.is_follow"
          @click="handleUnfollowUser"
          name="Unfollow"      
          :is-loading="isLoadingFollowUser"  
        />
        
      </div>

      <div class="or">
        OR
      </div>

      <div class="bl diary">
        <NuxtLink class="av" :to="diary.link">
          <img loading="lazy" :src="diary.avatar_little" :alt="diary.name" />
        </NuxtLink>
        
        <NuxtLink class="nm" :to="diary.link">
          {{ diary.name }}
        </NuxtLink>


        <UiButton
          v-if="!diary?.is_follow"
          @click="handleFollowDiary"
          name="Follow Diary"
          type="secondary"
          :is-loading="isLoadingFollowDiary"
        />

        <UiButton
          v-if="diary?.is_follow"
          @click="handleUnfollowDiary"
          name="Unfollow"    
          :is-loading="isLoadingFollowDiary"    
        />
        
      </div>

    </div>

  </div>
  
</template>

<script setup lang="ts">

import type { Diary } from '@/types/diary';

const { $api } = useNuxtApp();

interface Props {
  diary: Diary;
}

const props = defineProps<Props>();
const isLoadingFollowDiary = ref(false);
const isLoadingFollowUser = ref(false);

const handleUnfollowDiary = async () => {
  isLoadingFollowDiary.value = true;
  await useDiary().unfollow(props.diary);
  isLoadingFollowDiary.value = false;
}

const handleFollowDiary = async () => {
  isLoadingFollowDiary.value = true;
  await useDiary().follow(props.diary);
  isLoadingFollowDiary.value = false;
}

const handleUnfollowUser = async () => {
  isLoadingFollowUser.value = true;
  await useUser().unfollow(props.diary.item_user);
  isLoadingFollowUser.value = false;
}

const handleFollowUser = async () => {
  isLoadingFollowUser.value = true;
  await useUser().follow(props.diary.item_user);
  isLoadingFollowUser.value = false;
}

</script>

<style scoped>
 
 .flw{
  width: 100%;

 }

 
.cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;  
  margin: 1rem auto;
  max-width: 400px;
}

.bl {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(50% - 50px);
  flex-shrink: 0;
  text-align: center;
}

.av {
  width: 100px;    
  aspect-ratio: 1/1;
}
.av img{
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;  
}
.bl.diary .av img{
  border-radius: 10px;
}

.nm {
  font-weight: bold;
  margin-top: 0.4rem;
  margin-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  width: 100%;
}

.or {
  margin: 0 16px;
  font-weight: bold;
  color: var(--un-text-color-gray);
  width: 50px;
  text-align: center;
  flex-shrink: 0;
}


@container pb (max-width: 600px) {
  .cnt{
    flex-direction: column;
    gap: 1rem;
  }
  .bl{
    width: 100%;
  }
  .or{
    display: none;
  }
}
 
</style>
