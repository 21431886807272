<template>

  <div class="week" :ref="'week_' + props.weekId">
    
    <div :class="'cal cal-' + props.faza">{{ props.faza === -1 ? 'G' : props.days }}</div>

    <template v-if="props.days">
      Week {{ props.faza === -1 ? 'G' : props.days }}. 
    </template>
    {{ WeekFazaNameTr[props.faza] ? $t(WeekFazaNameTr[props.faza]) : '' }}

  </div>

</template>

<script setup lang="ts">

import type { WeekFaza } from '@/types/diary'
import { WeekFazaNameTr } from '@/types/diary'

interface Props {
  faza: WeekFaza;
  days?: number;
  weekId: number | null;
}

const props = defineProps<Props>()

</script>

<style scoped>
 

 .week{
  font-weight: bold;
    padding-bottom: 4px;
    z-index: 1;
    background-color: var(--un-background-color);
    border-radius: 5px;
    padding: 4px 12px;
    position: sticky;
    top: 60px;
    width: -moz-fit-content;
    width: fit-content;
    /* margin-top: 12px; */
    margin-bottom: 5px;
    font-size: 1.2rem;
    margin-left: -12px;
}
.is-modal .week{
  top:10px;
}


.cal{
  font-size: 1rem;
  font-weight: bold;
  color: var(--un-text-color-gray);
  background-image: url(/images/calendar/1.svg);
  aspect-ratio: 1 / 1;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
}

.cal.cal--1{
  background-image: url(/images/calendar/germination.svg);
}
.cal.cal-0{
  background-image: url(/images/calendar/vegetation.svg);
}
.cal.cal-1{
  background-image: url(/images/calendar/flowering.svg);
}
.cal.cal-2{
  background-image: url(/images/calendar/harvest.svg);
}



@container pb (max-width: 600px) {
  .week{
    font-size: 1rem;
  }
  .cal{
    font-size: 0.7rem;
    width: 30px;
    height: 30px;
    line-height: 40px;
  }

  
}


</style>
