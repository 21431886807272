<template>
  <div v-if="parameters.length"  class="params">
    <div class="st-bx">
      <div class="st">

        <div 
          v-for="(s, ks) in parameters"
          :key="ks"
          class="st-item"
          :data-param-id="s.id"
        >
          <div v-if="s.icon" :class="'elic ' + s.icon"></div>
          <img loading="lazy" v-else-if="s.img" :src="s.img" :alt="s.hint" class="elic">

          <div class="vl">
            {{ s.beforeValue }} 
            {{ s.unit ? $convert.view(s.unit, s.measure, s.value) : s.value }}    
            {{ s.afterValue }} 
            <template v-if="s.unit && s.measure && $constants[s.unit] && $constants[s.unit][s.measure]">
              {{ $constants[s.unit][s.measure] ? $t($constants[s.unit][s.measure]) : '' }}
            </template>
          </div>
          <div class="nm">{{ s.hint }}</div>

        </div>
      </div>
    </div>
  </div>


</template>

<script setup lang="ts">


import type { WeekProps, WeekHarvestReviewSeedPropsGrowingEnum, ReviewEffectPositive, ReviewEffectNegative, ReviewEffectMedical, ReviewTaste }  from "@/types/diary"
import { HarvestSeedPropsGrowingTr }  from "@/types/harvest"
import { ReviewTasteEnumInfo, ReviewEffectMedicalEnumInfo, ReviewEffectNegativeEnumInfo, ReviewEffectPositiveEnumInfo } from '@/types/diary';



interface PageProps {
  weekProps: WeekProps
  weekHarvestGrowing: WeekHarvestReviewSeedPropsGrowingEnum
  weekHarvestRate: number
  weekHarvestEffectPositive: ReviewEffectPositive,
  weekHarvestEffectNegative: ReviewEffectNegative,
  weekHarvestEffectMedical: ReviewEffectMedical,
  weekHarvestTaste: ReviewTaste
}
  
const { t } = useI18n();
const { $constants, $convert } = useNuxtApp();

const props = defineProps<PageProps>();
 

const parameters = computed(() => {
  var list = []; 

  if(!props.weekProps) return [];




  // if(props.weekHarvestRate){
  //   list.push({
  //     hint: 'Rated',
  //     value: props.weekHarvestRate + '/10',
  //     beforeValue: null,
  //     afterValue: null,
  //     icon: 'setup_ico setup_ico_rate',
  //     id: 'rate',
  //     unit: null,
  //     measure: null,
  //     onChange: () => {}
  //   });
  // }




      
  if(props.weekProps.harvest_wet_weight){
    let val = props.weekProps.harvest_wet_weight / (props.weekProps.harvest_plant || 1);
    val = Math.round(val*100)/100;
    list.push({
      tophint: '',
      hint: 'Bud wet weight per plant',
      value: val,
      beforeValue: null,
      afterValue: null,
      icon: 'setup_ico setup_ico_n_wet_weight',
      id: 'n_wet_weight',
      unit: 'weight',
      measure: useAuth().getWeight(),
      onChange: useAuth().setWeight
    });
  }


  if(props.weekProps.harvest_weight){
    let val = props.weekProps.harvest_weight / (props.weekProps.harvest_plant || 1);
    val = Math.round(val*100)/100;
    list.push({
      hint: 'Bud dry weight per plant',
      value: val,
      beforeValue: null,
      afterValue: null,
      icon: 'setup_ico setup_ico_n_weight',
      id: 'n_weight',
      unit: 'weight',
      measure: useAuth().getWeight(),
      onChange: useAuth().setWeight
    });
  }

  if(props.weekProps.harvest_plant){
    list.push({
      tophint: t('harvest_view_parameters_harvested'),
      // hint: t('harvest_view_parameters_number_of_plants'), //too long
      hint: 'Plants',
      value: props.weekProps.harvest_plant,
      beforeValue: null,
      afterValue: null,
      icon: 'setup_ico setup_ico_n_plant',
      id: 'n_plant',
      unit: 'plant',
      measure: null,
      onChange: null
    });
  }

  if(props.weekProps.harvest_space){
    list.push({
      tophint: t('harvest_view_parameters_grow_room'),
      hint: t('harvest_view_parameters_grow_room_size'),
      value: props.weekProps.harvest_space,
      beforeValue: null,
      afterValue: null,
      icon: 'setup_ico setup_ico_n_space',
      id: 'n_space',
      unit: 'square',
      measure: useAuth().getLength(),
      onChange: useAuth().setLength
    });
  }

  if(props.weekHarvestGrowing){
    list.push({
      hint: 'Difficulty',
      value: t(HarvestSeedPropsGrowingTr[props.weekHarvestGrowing]),
      beforeValue: null,
      afterValue: null,
      icon: 'setup_ico setup_ico_growing_' + props.weekHarvestGrowing,
      id: 'growing',
      unit: null,
      measure: null,
      onChange: () => {}
    });
  }


  if(props.weekHarvestEffectPositive?.length){
    list.push({
      hint: 'Positive effects',
      value: props.weekHarvestEffectPositive.map((e) => t(ReviewEffectPositiveEnumInfo[e.id]['tr'])).join(', '),
      beforeValue: null,
      afterValue: null,
      icon: null,
      img: ReviewEffectPositiveEnumInfo[props.weekHarvestEffectPositive[0].id]['img'],
      id: 'effect_positive',
      unit: null,
      measure: null,
      onChange: () => {}
    });
  }

  if(props.weekHarvestEffectNegative?.length){
    list.push({
      hint: 'Negative effects',
      value: props.weekHarvestEffectNegative.map((e) => t(ReviewEffectNegativeEnumInfo[e.id]['tr'])).join(', '),
      beforeValue: null,
      afterValue: null,
      icon: null,
      img: ReviewEffectNegativeEnumInfo[props.weekHarvestEffectNegative[0].id]['img'],
      id: 'effect_negative',
      unit: null,
      measure: null,
      onChange: () => {}
    });
  }

  if(props.weekHarvestEffectMedical?.length){
    list.push({
      hint: 'Medical effects',
      value: props.weekHarvestEffectMedical.map((e) => t(ReviewEffectMedicalEnumInfo[e.id]['tr'])).join(', '),
      beforeValue: null,
      afterValue: null,
      icon: null,
      img: ReviewEffectMedicalEnumInfo[props.weekHarvestEffectMedical[0].id]['img'],
      id: 'effect_medical',
      unit: null,
      measure: null,
      onChange: () => {}
    });
  }

  if(props.weekHarvestTaste?.length){
    list.push({
      hint: 'Taste',
      value: props.weekHarvestTaste.map((e) => t(ReviewTasteEnumInfo[e.id]['tr'])).join(', '),
      beforeValue: null,
      afterValue: null,
      icon: null,
      img: ReviewTasteEnumInfo[props.weekHarvestTaste[0].id]['img'],
      id: 'taste',
      unit: null,
      measure: null,
      onChange: () => {}
    });
  }







  



  return list;


});

</script>




<style scoped>
 .st-bx{
  position: relative;
 }
 .week_comment{
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 10px 0;
}
.date{
  font-size: 0.8rem;
  color: #999;
}

/* flex, overflow-x */
.st{
  display: flex;
  gap: 1rem 1.5rem;
  overflow-x: auto;
  margin-top: 10px;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}

.st::-webkit-scrollbar {
  display: none;
}

.st-item{
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  min-height: 40px;
  width: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.vl{
  font-weight: bold;
  font-size: 1.2rem;
  grid-area: vl;
  line-height: normal;
}

[data-param-id="effect_positive"] .vl,
[data-param-id="effect_negative"] .vl,
[data-param-id="effect_medical"] .vl,
[data-param-id="taste"] .vl{
  font-size: 0.9rem;
}
.nm{
  /* font-size: 0.8rem; */
  /* color: var(--un-text-color-gray); */
  line-height: 0.8rem;
  grid-area: nm;
}

.elic{
  font-size: 1.2rem;
  vertical-align: middle;
  grid-area: ic;
  flex-shrink: 0;
}
img.elic{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* .st-item::after{
  content: '·';
  margin-left: 7px;
  margin-right: 5px;
  color: var(--un-text-color-gray);

} */

.st-item:last-child::after{
  content: '';
}

.setup_ico{
  width: 60px;
  background-size: contain;
  aspect-ratio: 1/1;
}

.dots{
  padding: 6px 0;
    color: var(--un-text-color);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--un-background-color-gray);
    width: 52px;
    height: 35px;
    text-align: center;
    margin-top: -2px;
    font-size: 2rem;
    line-height: 0.9rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@container pc (max-width: 600px) {

  .st{
    gap: 0.5rem 0.5rem;
  }
  .st-item{
    width: 100%;
    display: grid;
    grid-template-areas: "ic nm vl";
    grid-template-columns: 45px 1fr 1fr;
  }
  img.elic,
  .elic{
    width: 45px;
    height: 45px;
  }
  .nm{
    text-align: left;
        /* font-weight: 600; */
  }
  .vl{

    font-size: inherit;
    text-align: right;
  }
  
 
}



</style>
