<template>
  <div class="spent-time">
    <div class="spent-time__map">
      <div class="spent-time__label">
        Spent {{ daysInFocus }} days
      </div>


      <div class="spent-time__legend">
        <span
          v-for="(segment, index) in segments"
          :key="index"
          class="spent-time__legend-item"
          :style="{
            opacity: hoveredSegment && hoveredSegment.id !== segment.id ? 0.3 : 1,
          }"
        >
          <span
            class="spent-time__legend-color"
            :style="{ backgroundColor: segment.color }"
          />
          {{ segment.label }}
        </span>
      </div>
    </div>


    <div class="spent-time__bar">
      <div
        v-for="(segment, index) in segments"
        :key="index"
        class="spent-time__segment"
        :style="{
          backgroundColor: segment.color,
          width: segment.percent + '%',
          opacity: hoveredSegment && hoveredSegment.id !== segment.id ? 0.3 : 1,
          zoom: hoveredSegment && hoveredSegment.id === segment.id ? '1.2' : '1'
        }"
        @mouseover="hoveredSegment = segment"
        @mouseleave="hoveredSegment = null"
        @touchstart="hoveredSegment = segment"
        @touchend="hoveredSegment = null"
      />
    </div>
  </div>

</template>



<script setup lang="ts">
import { computed, ref } from 'vue';
import { WeekFaza } from '~/types/diary';

interface Week {
  faza: number;
  days: number; // ← номер недели, не количество дней
}

interface PageProps {
  weeks: Week[]
}

const props = defineProps<PageProps>();


const gerDays = computed(() => {
  return props.weeks.filter((w) => w.faza === WeekFaza.Germination)?.length;
});

const vegDays = computed(() => {
  return props.weeks.filter((w) => w.faza === WeekFaza.Vegetation)?.length*7;
});

const floDays = computed(() => {
  return props.weeks.filter((w) => w.faza === WeekFaza.Flowering)?.length*7;
});

const harDays = computed(() => {
  return props.weeks.filter((w) => w.faza === WeekFaza.Harvest)?.length;
});

 

const totalDays = computed(() => {
  return gerDays.value + vegDays.value + floDays.value + harDays.value;
});


const segments = computed(() => {
  const all = totalDays.value;
  const rawSegments = [
    { id: "Ger", label: "Ger", days: gerDays.value, color: "#00ceb4" },
    { id: "Veg", label: "Veg", days: vegDays.value, color: "#3c9a3f" },
    { id: "Flo", label: "Flo", days: floDays.value, color: "#ff9800" },
    { id: "Har", label: "Har", days: harDays.value, color: "#e64a19" },
  ];



  // const filtered = rawSegments.filter((s) => s.days > 0);

  return rawSegments.map((s) => ({
    ...s,
    percent: all ? ((s.days / all) * 100).toFixed(2) : 0
  }));
});

const hoveredSegment = ref<null | (typeof segments.value)[0]>(null);

const daysInFocus = computed(() => {
  return hoveredSegment.value ? hoveredSegment.value.days : totalDays.value;
});
</script>


<style scoped>
.spent-time {
  width: 100%;
  max-width: 600px;
}

.spent-time__map {
  display: flex;
  justify-content: space-between;
}

.spent-time__label {
  margin-bottom: 8px;
  font-weight: 600;
}

.spent-time__bar {
  display: flex;
  height: 20px;
  border-radius: 50%;
  background: var(--un-background-color-gray);
  margin-bottom: 8px;
  align-items: center;
}

.spent-time__segment {
  height: 20px;
  cursor: pointer;
  transition: all 0.2s ease;

  min-width: 30px;
}
.spent-time__segment:first-child {
  border-radius: 14px 0 0 14px;
}

.spent-time__segment:last-child {
  border-radius: 0 14px 14px 0;
}

.spent-time__legend {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.spent-time__legend-item {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9rem;
}




.spent-time__legend-color {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
}
</style>