<template>


  <div class="hr" v-if="props.weekHarvest">

    <div class="title">

      <div class="fin">
        <img loading="lazy" class="ill" src="/images/diary-harvest-head.svg"/>
        <div class="tt">Happy Harvest Day!</div>          
      </div>
      
      <div class="pro">

        <div class="prod">
          <NuxtLink :to="props.weekHarvest.item_review_seed.item_brand.link" class="br_logo">
            <img loading="lazy" :src="props.weekHarvest.item_review_seed.item_brand.logo_s" :alt="props.weekHarvest.item_review_seed.item_brand.name" />
          </NuxtLink>
          <NuxtLink :to="props.weekHarvest.item_review_seed.item_product.link" class="pr_name">
            {{ props.weekHarvest.item_review_seed.item_product.name }}
          </NuxtLink>
          <NuxtLink :to="props.weekHarvest.item_review_seed.item_brand.link" class="br_name">
            {{ props.weekHarvest.item_review_seed.item_brand.name }}
          </NuxtLink>
        </div>

        <div class="rate" v-if="props.weekHarvest.item_review_seed.n_general">
          <!-- <i class="setup_ico setup_ico_rate"></i> -->
          <img loading="lazy" class="ri" :src="GradeStarImg[props.weekHarvest.item_review_seed.n_general ?? 0]" alt="Rated">
          <div class="nm">{{ props.weekHarvest.item_review_seed.n_general }}/10</div>
          <div class="rt">Rated</div>
        </div>  
        
        <GeneralShowMore
          class="comment"
          v-if="props.weekHarvest.item_review_seed.text"
          :desktop-max-height="100"
          :mobile-max-height="100" 
        >
          {{ props.weekHarvest.item_review_seed.text }}
        </GeneralShowMore>

      </div>

    </div>

    <WeekwideViewHarvestSpentTime
      :weeks="props.weeks"
    />

    <WeekwideViewHarvestParams
      :week-props="props.weekProps"
      :week-harvest-growing="props.weekHarvest.item_review_seed.props?.growing"
      :week-harvest-rate="props.weekHarvest.item_review_seed?.n_general"
      :week-harvest-effect-positive="props.weekHarvest.item_review_seed?.items_effect_positive"
      :week-harvest-effect-negative="props.weekHarvest.item_review_seed?.items_effect_negative"
      :week-harvest-effect-medical="props.weekHarvest.item_review_seed?.items_effect_medical"
      :week-harvest-taste="props.weekHarvest.item_review_seed?.items_taste"
    /> 

    <WeekwideViewHarvestIndicaSlider
      v-if="existsIndica"
      v-model="props.weekHarvest.item_review_seed.props.indica"
    />

    <WeekwideViewHarvestSummaryChart
      :weeks="props.weeks"
    />

  </div>

  
</template>

<script setup lang="ts">


import { WeekwideViewHarvestSummaryChart } from "#components";
import type { Week, WeekHarvest, WeekProps }  from "@/types/diary"
import {GradeStarImg }  from "@/types/other"

interface PageProps {
  weekHarvest: WeekHarvest,
  weekProps: WeekProps
  weeks: Week[]
}

const props = defineProps<PageProps>();

const existsIndica = computed(() => {
  if (!props.weekHarvest.item_review_seed.props?.indica) return false
  return true
})

</script>




<style scoped>
.hr{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.params {
  grid-area: params;
  width: 100%;
  /* margin: 1rem 0; */
}

.title{
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 1rem;
}


@container pb (min-width: 600px) {
  
}

/* pro */


.pro{
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-template-areas: "prod rate" "comment comment";
  gap: 0.7rem;
  align-items: center;
}

.pro .prod{
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 0rem 1rem;
  grid-template-areas: "logo name" "logo brand";  
  grid-area: prod;
}

.pro .prod .br_logo{
  grid-area: logo;
  width: 50px;
  height: 50px;
  background-size: cover;
  display: inline-block;
  aspect-ratio: 1 / 1;
}
.pro .prod .br_logo img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pro .prod .pr_name{
  grid-area: name;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: normal;
}

.pro .prod .br_name{
  grid-area: brand;
  font-size: 1rem;
  font-weight: normal;
  line-height: normal;
}


.pro .rate{
  display: grid;
  grid-template-columns: 35px 1fr;
  gap: 0rem 1rem;
  align-items: center;
  grid-template-areas: "ico nm" "ico rt";  
  grid-area: rate;
}

.pro .rate .ri{
  width: 35px;
  background-size: cover;
  display: inline-block;
  aspect-ratio: 1 / 1;
  margin-bottom: -3px;
  grid-area: ico;
}
.pro .rate .nm{
  font-size: 1.2rem;
  font-weight: 600;
  line-height: normal;
  grid-area: nm;
}

.pro .rate .rt{
  font-size: 1rem;
  font-weight: normal;
  line-height: normal;
  grid-area: rt;
}


.pro .comment{
  grid-area: comment;
}



/*  */
.fin{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.fin .tt{
  font-size: 1.5rem;
  font-weight: bold;
  line-height: normal;
  margin-top: -0.9rem;
  margin-bottom: 2.5rem;

  text-align: center;

  font-size: 1.4rem;
  font-weight: 600;
}

.fin .ill{
  max-width: 550px;
  width: 100%;
}
 

/*  */


.setup_ico{
  width: 25px;
  background-size: cover;
  display: inline-block;
  margin-left: 5px;
  aspect-ratio: 1 / 1;
  margin-bottom: -3px;
}


</style>
