<template>

<!-- {{chartData[0]}} -->
<!-- {{activePoint}} -->

<br>

  <UiHorizontalScroll
    :arrows="true"
    :arrows-show-hover="true"
  >
    <div class="types">
      <div
        v-for="type in chartData"
        :key="type.name"
        @click="activeSeries = type.prop"
        :class="{type, active: activeSeries === type.prop }"
      >
        <i :class="type.icon" v-if="type.icon"></i>
        {{ type.name }}
        </div>
    </div>    
  </UiHorizontalScroll>

  <!-- <div 
    class="active-point"
    v-if="activePoint"
  >
    <div class="ttl">
      {{ activePointTitle }}
    </div>
    <div class="vl">
      {{ activePointValue }}
    </div>
  </div> -->

  <div ref="chartContainer" style="width: 100%;"></div>
  
</template>

<script setup lang="ts">

import type { Week, WeekHarvest, WeekProps }  from "@/types/diary"

import { ref, onMounted, watch } from 'vue'
import {
  renderCharts,  
} from '~/components/chart-lib/lib'
import type {
  ChartSeries,
  ChartOptions,
  ChartPoint  
} from '~/components/chart-lib/lib'
import { getChartWeekData, getChartWeekMaxWeeks, getFirstActiveSeries } from '~/types/other'
import { DiaryChartTypes } from '~/types/diary'


const { $convert } = useNuxtApp();
const { t } = useI18n();

interface PageProps {
  weeks: Week[]
}

const props = defineProps<PageProps>();
const activeSeries = ref(getFirstActiveSeries(props.weeks))
const activePoint = ref(null)

const chartData = computed(() => {
  let chartData: ChartSeries[] = []
  let maxWeeks = getChartWeekMaxWeeks(props.weeks)
  let types = DiaryChartTypes

  for (let type of types) {

    // let unitMeasure = useAuth().getUnitMeasureByType(type.unit);
    // let unitMeasureName = $constants[type.unit][unitMeasure] ? $t($constants[type.unit][unitMeasure]) : '';
    let seriesData = getChartWeekData(props.weeks, type.prop, maxWeeks, type.unit, useAuth().getUnitMeasureByType(type.unit))
    let series = {
      name: type.name,
      prop: type.prop,
      icon: type.icon,
      // color: type.color,
      color: '#008957',

      // corridor: [
      // // week, height, deviation
      //   { x: 1, y: 0, d: 3 },
      //   { x: 2, y: 4, d: 4 },
      //   { x: 3, y: 10, d: 6 },
      //   { x: 4, y: 20, d: 8 },
      //   { x: 5, y: 30, d: 10 },
      //   { x: 6, y: 50, d: 12 },
      //   { x: 7, y: 60, d: 14 },
      //   { x: 8, y: 60, d: 16 },
      //   { x: 9, y: 60, d: 16 },
      //   { x: 10, y: 60, d: 16 },
      //   { x: 11, y: 55, d: 16 }, 
      // ],
      xAxisTitle: 'Weeks',
      xAxisUnit: null,
      yAxisTitle: type.name,
      yAxisUnit: t(useAuth().getUnitMeasureNameByType(type.unit)),
      data: seriesData,
      isActive: activeSeries.value === type.prop,      
      tooltipFormatter: (point, series) => {
        // let viewVl = $convert.view(type.unit, useAuth().getUnitMeasureByType(type.unit), point.y, 0)
        let viewVl = point.y
        // let viewVl = $convert.round(type.unit, useAuth().getUnitMeasureNameByType(type.unit), point.y)
        return `<div style="padding: 5px; font-size: 12px;">
          <strong>${series.name}</strong><br/>
          <div style="font-size:1.2rem;">${viewVl} ${series.yAxisUnit || ''}</div>
          Week ${point.x}
        </div>`;
      },
    }

    let existsAnyData = seriesData.some((point) => point.y !== null)
    if (existsAnyData){

      chartData.push(series)
    }

  }

  return chartData

})

const activePointTitle = computed(() => {
  // title - name chart name f active series
  let activeSeries = chartData.value.find((series) => series.isActive)
  return activeSeries ? activeSeries.name : ''
})

const activePointValue = computed(() => {
  // value - value of active point with unit measure
  let activeSeries = chartData.value.find((series) => series.isActive)
  if (activeSeries && activePoint.value) {
    return `${activePoint.value.y} ${activeSeries.yAxisUnit}`
  }
  return ''
})

// const chartData2 = ref<ChartSeries[]>([
//   {
//     name: 'Temperature',
//     color: '#008957',
//     data: [
//       { x: 0, y: 18 },
//       { x: 1, y: 22 },
//       { x: 2, y: 21 },
//       { x: 3, y: 24 },
//       { x: 4, y: 23 },
//     ],
//     corridor: [
//       { x: 0, y: 20, d: 3 },
//       { x: 1, y: 22, d: 3 },
//       { x: 2, y: 21, d: 3 },
//       { x: 3, y: 24, d: 3 },
//       { x: 4, y: 23, d: 4 },
//     ],
//     isActive: true,

//     // Axis labels/units for this series
//     xAxisTitle: 'Weeks',
//     xAxisUnit: null,
//     yAxisTitle: 'Measurement',
//     yAxisUnit: '%',
//   },
//   {
//     name: 'Humidity',
//     color: '#00AAFF',
//     data: [
//       { x: 0, y: 40 },
//       { x: 1, y: 35 },
//       { x: 2, y: 45 },
//       { x: 3, y: 50 },
//       { x: 4, y: 42 },
//     ],
//     // no corridor, not active => only points in grey
//     // no axis labels or units => won't be shown if this becomes active
//   },
// ])

// Container ref
const chartContainer = ref<HTMLElement | null>(null)

// Point-click handler
function handlePointClick(point: ChartPoint, series, seriesIndex, pointIndex) {
  activePoint.value = point
  // console.log('Clicked point:', {
  //   point,
  //   series: series.name,
  //   seriesIndex,
  //   pointIndex,
  // })
}

// Chart options (global)
const options: ChartOptions = {
  axisColor: '#666',
  height: 300,
  animate: true,         // simple path-draw animation
  margin: { top: 20, right: 20, bottom: 50, left: 60 },
  showTooltip: true,     // enable simple "native" tooltip on hover
  showAnomalies: true,   // highlight anomalies in red
  showInactive: false,    // show inactive series in grey
  onPointClick: handlePointClick,
}

onMounted(() => {
  if (chartContainer.value) {
    renderCharts(chartContainer.value, chartData.value, options)
  }
})

watch(chartData, () => {
  if (chartContainer.value) {
    renderCharts(chartContainer.value, chartData.value, options)
  }
}, { deep: true })

</script>



<style scoped>

.types {
  display: flex;
  /* gap: 1rem; */
  width: 100%;
}

.type {
  padding: 0.5rem 0.7rem;
  border-radius: 0.5rem;
  cursor: pointer;
  white-space: nowrap;
}

.active {
  background-color: var(--un-background-color-gray);
  /* font-weight: 600; */
}


/*  */

.active-point{
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #f0f0f0;
}

.active-point .ttl{
  font-weight: 600;
}

.active-point .vl{
  font-weight: 600;
}

</style>