<template>
  <div class="galpan">
    <WeekwideViewWeek
      v-for="(week, index) in props.diary.items_week"
      :key="week.id"
      :week="week"
      :diary="props.diary"
      :week-active="props.weekActive"
      :problems="props.diary.items_problem?.filter((p: Question) => p.item_week?.id == week.id)"
      :comments="props.comments"
      :num="index"
      @share-week="emits('shareWeek')"
      @remove-week="handleRemoveWeek"
      :ref="(childExpose) => {
        if (childExpose) setWeekRef(childExpose.rootEl, index)
      }"
    />
  </div>
</template>

<script setup lang="ts">

import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useIntersectionObserver, useDebounceFn, type Stoppable } from '@vueuse/core'


import type { Diary, Week } from '@/types/diary'
import type { Question } from '@/types/question'


const emits = defineEmits(['interactWeek', 'leaveWeek', 'removeWeek', 'shareWeek'])


interface PageProps {
  diary: Diary,
  weekActive: Week | null,
  comments: any,
  isModal: boolean
}
const props = defineProps<PageProps>()


const weekRefs = ref<Element[]>([])

const observers: Stoppable[] = []


const isObserverDisabled = ref(false)
const scrollableTopMargin = ref(0)

const isScrolledViewWeek = ref(false)
const scrollableEl = ref<HTMLElement | null>(null)


const setWeekRef = (el: HTMLElement, index: number) => {
  weekRefs.value[index] = el
}


const handleRemoveWeek = (week: Week) => {
  emits('removeWeek', week)
}

const handleDebouncedScroll = useDebounceFn(() => {
  handleScrollEnd()
  // console.log('debounced')
  // console.log('isScrolledViewWeek.value', isScrolledViewWeek.value)
  // console.log('isObserverDisabled.value', isObserverDisabled.value)
}, 200)



onMounted(() => {
  weekRefs.value.forEach((weekEl, index) => {
    const stopObserver = useIntersectionObserver(
      () => weekRefs.value[index] as HTMLElement,
      ([{ isIntersecting }]) => {


        if (isObserverDisabled.value) return

        if (isIntersecting) {
          emits('interactWeek', props.diary.items_week[index])
          isScrolledViewWeek.value = true
        }else{
          emits('leaveWeek', props.diary.items_week[index])
        }
      },
      {
        root: null,
        rootMargin: '0px 0px -20% 0px',
        threshold: 0.3
      }
    )
    observers.push(stopObserver)
  })


  handleScrollEnd();
  // scrollableEl.value.addEventListener('scroll', handleScroll, { passive: true })
})

const handleScrollEnd = () => {
  isObserverDisabled.value = false
  isScrolledViewWeek.value = false

};

onBeforeUnmount(() => {
  observers.forEach(observer => observer.stop())
  // scrollableEl.value.removeEventListener('scroll', handleScroll)
})


if(import.meta.client){  
  
  onMounted(() => {

    scrollableEl.value = props.isModal ? useModalsDiaryModalRef().value : window;
    scrollableTopMargin.value = props.isModal ? 0 : -60;
    scrollableEl.value.addEventListener('scrollend', handleScrollEnd);    

    setTimeout(() => {
      handleScrollEnd();
    }, 200);

    scrollableEl.value.addEventListener('scroll', handleDebouncedScroll, { passive: true })

  });

  onBeforeUnmount(() => {
    scrollableEl.value.removeEventListener('scrollend', handleScrollEnd);
    scrollableEl.value.removeEventListener('scroll', handleDebouncedScroll)
  });
  
  

}



watch(
  () => props.weekActive,
  (newVal, oldVal) => {

  if (isScrolledViewWeek.value) return
  
  isObserverDisabled.value = true

  props.diary.items_week.forEach((w, i) => {
    if (newVal?.id && newVal.id === w.id && oldVal?.id !== newVal.id) {
      scrollableEl.value.scrollTo({
        top: weekRefs.value[i].getBoundingClientRect().top + (props.isModal ? scrollableEl.value.scrollTop : window.scrollY) + scrollableTopMargin.value,
        behavior: 'smooth'
      }) 
    }
  })

}, { deep: true })

</script>

<style scoped>
.galpan {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  width: 100%;
}
</style>