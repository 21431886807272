<template>

  <div 
    v-if="props.week?.id" 
    :class="{'comment': true, 'is-more': isMoreButton}" 
  >
    <div class="ttl">

      <NuxtLink class="lnk" :to="props.itemUser.link">
        <img loading="lazy" v-if="props.itemUser?.avatar_little" class="ava" :src="props.itemUser.avatar_little" :alt="props.itemUser.name">
      </NuxtLink>
      
      <NuxtLink class="lnk" :to="props.itemUser.link">
        {{ props.itemUser.name }}
      </NuxtLink>
      <!-- <span class="cnt">{{ 1 + props.week?.cnt_comments }}</span> -->
    </div>
    
    <div class="item">
      <!-- <img loading="lazy" v-if="props.itemUser?.avatar_little" class="ava" :src="props.itemUser.avatar_little" :alt="props.itemUser.name"> -->

      <!-- <div :class="{'text': true, 'text-ellipsis-3': true}" ref="text">        
        {{ props.week.text ? props.week.text : '—' }}
      </div>
      <div v-if="isMoreButton" class="is-more-box">Show more</div> -->
      
      <div :class="{'text': true}" ref="text">        
        {{ props.week.text ? props.week.text : '—' }}
      </div>

      <!-- <div class="date">
        {{ $dayjs(props.week.add_date).fromNow() }}
      </div> -->

    </div>

    

  </div>



  <div v-if="props.week?.id" class="actions">
    <div :class="'act act-like ' + (props.week?.is_liked ? 'active' : '')" @click="handleLike">
      <i v-if="props.week?.is_liked" class="icon-heart-outline-fill"></i>
      <i v-else class="icon-heart-outline"></i>
      {{ props.week?.cnt_likes ? props.week?.cnt_likes : '' }}
      {{ $plurals.get('like', props.week?.cnt_likes) }}
    </div>
    <div class="act act-comment" @click="handleComments">
      <i class="icon-comment-outline"></i>
      {{ props.week?.cnt_comments ? props.week?.cnt_comments : '' }}
      {{ $plurals.get('comment', props.week?.cnt_comments) }}
    </div>
    <div class="act act-comment" @click="shareShow = true">
      <i class="icon-send-outline"></i>
      Share
    </div>
  </div>


  <GeneralShareModal
    v-if="shareShow"
    :showed="shareShow"
    @close="shareShow = false"
  />

  
</template>

<script setup lang="ts">

import type { Diary, Week } from '@/types/diary'
import type { User } from '@/types/user'

const { $likes } = useNuxtApp();

const likeLoading = ref(false);

interface PageProps {
  week: Week,
  itemUser: User,
  weekId: number,
  comments: any
}
const props = defineProps<PageProps>()
  

const text = ref(null)
const shareShow = ref(false);


// const comment = computed(() => {  
//   return props.comments.items_comment.filter(comment => comment.post_id === props.weekId)[0] || null
// })


import { inject } from 'vue';

const actionsToggleCommentEvent = inject('actionsToggleCommentEvent');

const handleComments = (event: string) => {
  actionsToggleCommentEvent({ weekId: props.week?.id });
};


const isMoreButton = computed(() => {
  if(import.meta.server) return false;
  return text.value?.clientHeight < text.value?.scrollHeight
});



const handleLike = () => {


  if(!useAuth().isAuth())
      return useAuth().goToLogin();
    
  likeLoading.value = true;
  $likes.toggle('week', props.week.id, props.week?.is_liked ? true : false)
  .then((res) => {
    likeLoading.value = false;
    props.week.is_liked = !props.week.is_liked;
    props.week.cnt_likes = props.week.is_liked ? props.week.cnt_likes + 1 : props.week.cnt_likes - 1;
    // emits('like', props.week.id);
  })
  .catch((err) => {
    likeLoading.value = false;
    console.log(err);
  })
  // likes.value++;

}


</script>




<style scoped>

.comment{
  background-color: var(--un-background-color-gray);
  border-radius: 10px;
  padding: 1rem;
  margin-top: 1rem;
}

.ttl{
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.cnt{
  font-size: 0.8rem;
  color: #999;
}

.item{
  display: flex;
  margin-top: 0.6rem;
  gap: 0.6rem;
  align-items: center;
  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 
    "text";

}
.comment.is-more .item{
  grid-template-areas: 
    "text"
}
.lnk{
  display: block;
}
.ava{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-bottom: auto;
  margin-top: 0;
  grid-area: ava;
  display: block;
}

.text{
  /* max-height: calc(1.4em* 3); */
  /* overflow: hidden; */
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  grid-area: text;
  word-break: break-word;
}

.is-more-box{
  display: none;
}
.text.is-more{
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.is-more .is-more-box2{
  display: block;
  cursor: pointer;
  color: var(--un-color-primary);
  font-weight: bold;
  position: absolute;
  background: linear-gradient(to right, var(--un-background-color-gray-transparent-0) 0%, var(--un-background-color-gray) 40%);
  right: 0;
  bottom: -1px;
  padding-left: 2rem;
}

.is-more-box{
  display: flex;
  font-size: 0.75rem;
    font-weight: 500;
    text-decoration: underline;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    min-height: 1rem;

  grid-area: more;
}
 
@container pb (max-width: 600px) {

  
}


/* actions */

.actions{
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;  
  margin-left: 15px;
}

.act{
  display: flex;
  gap: 0.4rem;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
}
.act.active,
.act:hover{
  color: var(--un-firm);
}
.act i{
  font-size: 1.2rem;
}





</style>
