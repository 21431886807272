<template>

  <ClientOnly>


    <Teleport to="#context" v-if="diary">

      <div class="ctx" @click="activeContext = true">
        <i class="icon-dots"></i>
      </div>
        

      <UiModal
        v-if="activeContext"
        :title="''"
        :close-button-name="'Close'"
        :is-transparent="true"
        :is-auto-height="true"
        :width="'70%'"
        :max-width="'400px'"
        @close="activeContext = false"         
        >        
        
        <div class="context_list">
 
            <img loading="lazy" v-if="diary.avatar_small" class="avatar" :src="diary.avatar_small" />
            
            <div 
              class="actions" 
              v-if="useAuth().isOwner(diary.item_user.id)"
              >    

              <UiButton
                tag="NuxtLink"             
                :name="$t('diary_info_button_edit')"
                ico="icon-pencil"
                type="float"
                @click.native="activeContext = false" 
                :to="'/diaries/edit/' + diary.id"
                />

              <UiButton
                :name="$t('diary_info_button_remove')"
                ico="icon-remove"
                type="float"
                @click.native="activeContext = false; handleRemoveDiary()" 
                />

              <UiButton
                v-if="!diary.is_hidden"
                :name="$t('diary_info_button_hide')"
                ico="icon-not-eye"
                type="float"
                @click.native="activeContext = false; handleUnpublishDiary()" 
                />

              <UiButton
                v-if="diary.is_hidden"
                :name="$t('diary_info_button_publish')"
                ico="icon-eye"
                type="float"
                @click.native="activeContext = false; handlePublishDiary()" 
                />

              <UiButton
                :name="$t('diary_info_button_add_week')"
                ico="icon-plus"
                type="float"
                @click.native="activeContext = false; handleAddWeek()" 
                />

              <!-- <UiButton
                v-if="week?.id"
                tag="NuxtLink"      
                :name="$t('diary_info_button_edit_week')"
                ico="icon-pencil"
                type="float"
                @click.native="activeContext = false; handleEditWeek()" 
                :to="'/diaries/' + diary.link + '/edit/week/' + week.id"
                /> -->

              <!-- <UiButton
                v-if="week?.id"
                tag="NuxtLink"      
                :name="$t('harvest_view_button_edit_smoke')"
                ico="icon-pencil"
                type="float"
                @click.native="activeContext = false; handleEditSmokeWeek()" 
                :to="'/diaries/' + diary.link + '/edit/smoke/' + week.id"
                /> -->

              <!-- <UiButton
                :name="$t('diary_info_button_remove_week')"
                ico="icon-remove"
                type="float"
                @click.native="activeContext = false; handleRemoveWeek()" 
                /> -->

              <UiButton
                v-if="copyIsSupported"
                :name="$t('diary_info_button_copy_link')"
                ico="icon-share"
                type="float"
                @click.native="activeContext = false; handleCopyLink()" 
                />


            </div>

            <div v-else>
              

              <template v-if="useAuth().isAuth()">

                <!-- <UiButton
                  :name="$t('diary_info_like_week')"
                  ico="icon-leaf"
                  type="float"
                  @click.native="activeContext = false; likeWeek()" 
                  />

                <UiButton
                  :name="$t('diary_info_unlike_week')"
                  ico="icon-leaf"
                  type="float"
                  @click.native="activeContext = false; unlikeWeek()" 
                  /> -->

                <UiButton
                  v-if="!diary?.is_follow"
                  :name="$t('diary_info_follow_diary')"
                  ico="icon-diary"
                  type="float"
                  @click.native="activeContext = false; handleFollowDiary()" 
                  />

                <UiButton
                  v-if="diary?.is_follow"
                  :name="$t('diary_info_unfollow_diary')"
                  ico="icon-diary"
                  type="float"
                  @click.native="activeContext = false; handleUnfollowDiary()" 
                  />

                <UiButton
                  v-if="diary?.item_user?.link && !diary?.item_user?.is_remove && !diary?.item_user?.is_hidden"
                  tag="NuxtLink"      
                  :name="$t('diary_info_show_author') + ' - ' + diary?.item_user?.name"
                  ico="icon-user"
                  type="float"
                  @click.native="activeContext = false;" 
                  :to="diary?.item_user?.link"
                  />

                <UiButton
                  v-if="copyIsSupported"
                  :name="$t('diary_info_button_copy_link')"
                  ico="icon-share"
                  type="float"
                  @click.native="activeContext = false; handleCopyLink()" 
                  />

              </template>



              <UiButton
                  tag="NuxtLink"
                  ico="icon-plus"   
                  type="float"
                  :name="$t('main_menu_start_diary')"
                  :to="'/diaries/edit/new'"
                /> 
  
            </div> 
        </div>
      </UiModal>
    </Teleport>    
  </ClientOnly>

</template>

<script setup lang="ts">
 
import { useClipboard } from '@vueuse/core'
import type { Diary, Week } from '@/types/diary'


const { text: copyText, copy, copied: copyCopied, isSupported: copyIsSupported } = useClipboard()

const emits = defineEmits([
  'removeDiary', 
  'updateWeek', 
  'cancelEditWeek',
  'addWeek',
  'editWeek',
  'editSmoke',
  'removeWeek',
  'copyLink',
  'likeWeek',
  'unlikeWeek',
  'followDiary',
  'unfollowDiary',
  'publishDiary',
  'unpublishDiary',
]);

interface PageProps {
  diary: Diary | null,
  week: Week | null,
}

const props = defineProps<PageProps>()

var activeContext = ref(false)

const handleRemoveDiary = function(){
  emits('removeDiary')
}

const handleUnpublishDiary = function(){
  emits('unpublishDiary')
}

const handlePublishDiary = function(){
  emits('publishDiary')
}

const handleAddWeek = function(){
  emits('addWeek')
}

const handleEditWeek = function(){
  emits('editWeek')
}

const handleEditSmokeWeek = function(){
  emits('editSmoke')
}

const handleRemoveWeek = function(){
  emits('removeWeek')
}

const handleCopyLink = function(){
  copy(document.location.href)
}

const handleLikeWeek = function(){
  emits('likeWeek')
}

const handleUnlikeWeek = function(){
  emits('unlikeWeek')
}

const handleFollowDiary = function(){
  emits('followDiary')
}

const handleUnfollowDiary = function(){
  emits('unfollowDiary')
}



 
</script>

<style scoped>
  .ctx{        
    min-width: 59px;
    display: flex;
    justify-content: center;
  }
  .ctx > i{    
    padding: 14px 20px 14px 25px;
    cursor: pointer;
  }

  /*  */

  .context_list{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
  .avatar{

    width: 150px;
    margin: 0 auto;
    border-radius: 20px;
    margin-bottom: 1rem;
    aspect-ratio: 1/1;
  }
</style>
