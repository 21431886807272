<template>
  <div class="empty">    

    <template v-if="props.isOwner">

      <div class="start">

        <i class="icon-rocket rk"></i>

        <div class="txt">
          {{ $t('diary_info_start_first_week') }}
        </div>

        <UiButton type="primary" :name="$t('diary_info_get_it_rolling')" @click="emits('create')"/>      

      </div>

    </template>

    <template v-else>

      <div class="start">

        <i class="icon-rocket rk"></i>

        <div class="txt">
          {{ $t('diary_info_no_data_to_display') }}
        </div>        

      </div>

    </template>
    
    

  </div>
</template>

<script setup lang="ts">
 
interface PageProps {
  isOwner: boolean
}

const props = defineProps<PageProps>()
const emits = defineEmits(['create'])




</script>

<style scoped>

.empty{
  margin: 0 auto;
  width: 100%;
}
.start{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
  margin-bottom: 9rem;
}
.start .rk{
  font-size: 3rem;
}
.start .txt{
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}



</style>
