<template>
 
    <div 
      v-if="data"
      :class="{'pr': true, 'is-single': sortedData?.length === 1, 'is-double': sortedData?.length === 2}" 
    >

      <div class="ttl">Equipment Reviews</div>

      <div class="its">

        <template 
          v-for="(item_review, items_category) in sortedData"
          :key="items_category"
        >
          <template
            v-for="(review_c, review_c_key) in item_review.items_review"
            :key="review_c_key"
            
          >
            <div class="it" v-if="review_c.n_general">
              <NuxtLink v-if="review_c.item_product.id && review_c.item_product.cover_s" :to="review_c.item_product.link" class="photo">
                <img loading="lazy" :src="review_c.item_product.cover_s" class="photo_img">
              </NuxtLink>
              <div v-else  class="photo">
                <img loading="lazy" :src="ProductCategoryImage[review_c.item_product.category]" class="photo_img">
              </div>

              <div class="info">

                <NuxtLink v-if="review_c.item_product.id" class="prod" :to="review_c.item_product.link">{{ review_c.item_product.name }}</NuxtLink>
                <div v-else class="prod">{{ review_c.item_product.name }}</div>

                <NuxtLink v-if="review_c.item_brand.id" class="bran" :to="review_c.item_brand.link">{{ review_c.item_brand.name }}</NuxtLink>
                <div v-else class="bran">{{ review_c.item_brand.name }}</div>

              </div>

              <div class="rate">
                <img loading="lazy" class="rt" :src="GradeStarImg[review_c.n_general]" alt="Rated">
                <div class="ri">
                  <span class="rate_val">{{ review_c.n_general }}/10</span>
                  <span class="rate_hint">Rated</span>
                </div>
              </div>

            </div>
          </template>
        </template>


      </div>
    </div>

</template>

<script setup lang="ts">


import { ProductCategorySort, ProductCategoryImage }  from "@/types/product"
import { GradeStarImg }  from "@/types/other"
 

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const sortedData = computed(() => {
  const result = props.data

  result.sort((a, b) => {
    return ProductCategorySort.indexOf(a.item_brand.category) - ProductCategorySort.indexOf(b.item_brand.category);
  })

  return result
})

const sortByCategory = (a, b) => {
  return ProductCategorySort.indexOf(a.item_brand.category) - ProductCategorySort.indexOf(b.item_brand.category);
}

const aggrData = computed(() => {

  const aggregated = props.data.reduce((acc, item) => {
    if (!acc[item.item_brand.category]) {
      acc[item.item_brand.category] = []
    }
    acc[item.item_brand.category].push(item)
    return acc
  }, {})

  // Sort the aggregated data by category
  const sortedAggregated = {}
  ProductCategorySort.forEach(category => {
    if (aggregated[category]) {
      sortedAggregated[category] = aggregated[category]
    }
  })

  return sortedAggregated
})

</script>

<style scoped>

.product_review{
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  align-items: flex-start;
  width: 100%;
}


@container pb (max-width: 600px) {
 
}





.ttl{
  font-weight: 700;
  margin-bottom: 0.5rem;
}


.its{
  margin-top: 0.5rem;
  display: flex;
  grid-template-columns: 100%;
  gap: 0.7rem;
  flex-wrap: wrap;
}
.pr{
  margin-top: 1.5rem;
}
.pr .it{
  display: flex;
  width: calc(100%/2 - 1rem);
  gap: 0.5rem;


}
.pr .it .photo{
  display: inline-block;
  vertical-align: top;
  max-height: 46px;
  width: 50px;
  text-align: center;
  flex-shrink: 0;
}
.pr .it .photo .photo_img{
  max-height: 35px;
  aspect-ratio: 1/1;
  object-fit: contain;
  border-radius: 5px;
}
.pr .it .info{
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 150px);
}
.pr .it .info > a{
  color: var(--un-text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.pr .it .info .prod{
  font-weight: 700;
}

.rate{
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
}
.ri{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  width: 40px;
}
.rt{
  background-size: contain;
  aspect-ratio: 1/1;
  width: 30px;
  height: 30px;  
}
.rate_val{
  font-weight: 700;
}
.rate_hint{
  font-size: 0.75rem;
}

@container pb (max-width: 600px) {
  .its .it{
    width: 100%;

  }
  .rw {
    min-width: 75vw;
  }

  .pr .its{
    /* grid-template-columns: 100%; */
  }
  
}

</style>