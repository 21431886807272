<template>

  <div class="flw" v-if="!useExperimentsDiaryWide().value">
  
    <div class="dot-ttl">Prefer the old Diary view?</div>

    <div class="cnt">

      <UiButton
        @click="handleBackToOld"
        name="Go back to the old Diary view"
        type="secondary"
      />

    </div>

  </div>

  <div class="flw" v-else>
  
    <div class="dot-ttl">Check out our new Diary view!</div>

    <div class="cnt">

      <UiButton
        @click="handleBackToNew"
        name="Switch to the new Diary view"
        type="secondary"
      />

    </div>

  </div>


  
</template>

<script setup lang="ts">

const { $ga } = useNuxtApp()

const handleBackToOld = () => {

  onMounted(() => {
    $ga.eventGA('diary_redesign', 'switch_to', 'v2');
  })

  useExperimentsDiaryWide().value = !false

  if (document.location.pathname.includes('/weeks')) {
    document.location.href = document.location.href.split('/weeks')[0]
  }else{
    document.location.reload()
  }

}

const handleBackToNew = () => {

  onMounted(() => {
    $ga.eventGA('diary_redesign', 'switch_to', 'v1');
  })

  useExperimentsDiaryWide().value = !true

  if (document.location.pathname.includes('/week')) {
    document.location.href = document.location.href.split('/week')[0]
  }else{
    document.location.reload()
  }

}



</script>

<style scoped>
 
 .flw{
  width: 100%;

 }

 
.cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;

}

.bl {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.av {
  margin-right: 8px;
  border-radius: 50%;
  overflow: hidden;
  aspect-ratio: 1/1;
  width: 100px;
}
.bl.diary .av{
  border-radius: 10px;
}

.nm {
  font-weight: bold;
  margin-top: 0.4rem;
  margin-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.or {
  margin: 0 16px;
  font-weight: bold;
  color: #888;
}
 
</style>
