<template>

  <div class="actions">

    <template v-if="props.diary?.item_user?.id && useAuth().isOwner(props.diary?.item_user.id)">

      <UiButtonDropdown
        :name="'Settings'"
        :ico="'icon-settings'"
        :options="actionsEditDiary"
        @choose="handleDropdown" 
        />
     
    </template>

    <template v-else>

      <UiButton
        v-if="!props.diary?.is_follow"
        @click="handleFollowDiary"
        name="Follow"
        :is-loading="isLoadingFollowDiary"
      />

      <UiButton
        v-if="props.diary?.is_follow"
        @click="handleUnfollowDiary"
        name="Unfollow"        
        :is-loading="isLoadingFollowDiary"
      />      
      
    </template>

  </div>
  
</template>

<script setup lang="ts">

import type { Diary, Week } from '@/types/diary'

const emits = defineEmits([
  'followDiary',
  'unfollowDiary',
  'removeDiary',
  'unpublishDiary',
  'publishDiary'
]);


interface PageProps {
  diary?: Diary,
}
const props = defineProps<PageProps>()

const isLoadingFollowDiary = ref(false);

const actionsEditDiary = computed(() => {
  var actions = [];

  actions.push({
    name: 'Edit diary',
    link: '/diaries/edit/' + props.diary?.id
  });

  if(props.diary?.is_hidden)
    actions.push({
      name: 'Publish diary',
      event: 'publishDiary'
    });
  else
    actions.push({
      name: 'Hide diary',
      event: 'unpublishDiary'
    });

  actions.push({
    name: 'Remove diary',
    event: 'removeDiary'
  });

  return actions;
});


const handleFollowDiary = async () => {
  isLoadingFollowDiary.value = true;
  await useDiary().follow(props.diary);
  isLoadingFollowDiary.value = false;
  emits('followDiary');
};

const handleUnfollowDiary = async () => {
  isLoadingFollowDiary.value = true;
  await useDiary().unfollow(props.diary);
  isLoadingFollowDiary.value = false;
  emits('unfollowDiary');
};

const handleDropdown = (event: Event, option: { event: string }) => {
  switch(option.event){
    case 'removeDiary':
      emits('removeDiary');
      break;
    case 'unpublishDiary':
      emits('unpublishDiary');
      break;
    case 'publishDiary':
      emits('publishDiary');
      break;
  }
};
 
 
</script>

<style scoped>

.actions{
  display: flex;
  justify-content: center;
}

@container pb (max-width: 600px) {
  .actions{
    display: none;
  }
}
</style>
